<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="总量" >
              <a-input-number :min="0" v-decorator="['total_count', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="批次" >
              <a-input v-decorator="['batch', {rules: [{required: model.goods_type===2, message: '必填项，请填写信息'}, {max:30, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="商品编码">
              <a-input v-decorator="['gcode', {rules: [{required: model.goods_type===2, message: '必填项，请填写信息'}, {max:10, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="企业商品编号">
              <a-input v-decorator="['item_no', {rules: [{required: model.goods_type===2, message: '必填项，请填写信息'}, {max:30, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="账册备案序号">
              <a-input v-decorator="['item_record_no', {rules: [{required: model.goods_type===2, message: '必填项，请填写信息'}, {max:30, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="账册编号" >
              <a-input v-decorator="['ems_no', {rules: [{required: false, message: '默认使用配置账册'}, {max:30, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="条形码">
              <a-input v-decorator="['barcode', {rules: [{required: false, message: '必填项，请填写信息'}, {max:16, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="法定单位" >
              <a-input disabled v-decorator="['legal_unit', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="法定单位2" >
              <a-input disabled v-decorator="['legal_unit2', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="售卖单位" >
              <a-select
                disabled
                v-decorator="[
                  'unit',
                  {
                    rules: [
                      { required: model.goods_type===2, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.units">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="售卖/法定单位比例" >
              <a-input v-decorator="['rate', {rules: [{required: model.goods_type===2, message: '必填项，请填写信息'}]}]" style="width: 200px" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="售卖/法定单位2比例" >
              <a-input v-decorator="['rate2', {rules: [{required: model.goods_type===2, message: '必填项，请填写信息'}]}]" style="width: 200px" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="每单位净重（千克）" >
              <a-input v-decorator="['net_weight', {rules: [{required: model.goods_type===2, message: '必填项，请填写信息'}]}]" style="width: 200px"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="每单位毛重（千克）" >
              <a-input v-decorator="['gross_weight', {rules: [{required: model.goods_type===2, message: '必填项，请填写信息'}]}]" style="width: 200px" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="品牌、规格、型号" >
              <a-textarea v-decorator="['model', {rules: [{required: model.goods_type===2, message: '必填项，请填写信息'}, {max:250, message: '输入超出长度限制'}]}]" :auto-size="{ minRows: 3, maxRows: 5 }"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="备注" >
              <a-textarea v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}, {max:256, message: '输入超出长度限制'}]}]" :auto-size="{ minRows: 3, maxRows: 5 }"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="开启保质期" >
              <a-switch
                @change="handleLifeChange"
                v-decorator="['open_self_life', {valuePropName: 'checked'},{rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="is_show">
            <a-form-item label="保质期开始时间" extra="格式: YYYY-mm-dd">
              <a-date-picker
                v-decorator="['self_life_begin', {rules: [{required: false, message: '必填项，请填写信息'}]}]"
                show-time
                :disabled-date="disabledStartDate"
                format="YYYY-MM-DD"
                placeholder="开始时间"
                :open="startOpen"
                value-format="yyyy-MM-DD"
                @openChange="handleStartOpenChange"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="is_show">
            <a-form-item label="结束日期自动计算">
              <a-switch
                @change="handleEndChange"
                v-decorator="['auto_calculate', {initialValue: true, valuePropName: 'checked'}, {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="!is_end && is_show">
            <a-form-item label="保质期结束时间" extra="格式: YYYY-mm-dd">
              <a-date-picker
                v-decorator="['self_life_end', {rules: [{required: false, message: '必填项，请填写信息'}]}]"
                show-time
                :disabled-date="disabledEndDate"
                format="YYYY-MM-DD"
                placeholder="结束时间"
                :open="endOpen"
                value-format="yyyy-MM-DD"
                @openChange="handleEndOpenChange"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { goods_list } from '@/api/c_wms_goods'
import { supplier_list } from '@/api/supplier'
import storage from 'store'
import { UNITS } from '@/store/mutation-types'

// 表单字段
const fields = ['wms_supervised_warehouse_goods_id', 'open_self_life', 'legal_unit', 'legal_unit2', 'unit']

export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      fetching: false,
      corporationList: [],
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      wms_warehouses: [],
      dataSource_wms_goods: [],
      units: undefined,
      endOpen: false,
      startOpen: false,
      is_show: false,
      is_end: true
    }
  },
  created () {
    this.units = storage.get(UNITS)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    setTimeout(() => {
      this.model.wms_supervised_warehouse_goods_id = this.model.id
      // this.model.unit = this.dictionaries.unit[this.model.unit]
      // this.model.legal_unit = this.dictionaries.unit[this.model.legal_unit]
      // this.model.legal_unit2 = this.dictionaries.unit[this.model.legal_unit2]
      // this.model && this.form.setFieldsValue(pick(this.model, fields))
      this.handleLifeChange(this.model.open_self_life)
      this.handleExhibitionGoodsSearch(this.model.name)
    })
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model.wms_supervised_warehouse_goods_id = this.model.id
      // this.model.unit = this.dictionaries.unit[this.model.unit]
      // this.model.legal_unit = this.dictionaries.unit[this.model.legal_unit]
      // this.model.legal_unit2 = this.dictionaries.unit[this.model.legal_unit2]
      // this.model && this.form.setFieldsValue(pick(this.model, fields))
      this.handleLifeChange(this.model.open_self_life)
      this.handleExhibitionGoodsSearch(this.model.name)
    })
  },
  mounted () {
    this.$nextTick(() => {
      this.loadEditInfo(this.model)
    })
  },
  methods: {
    fetchData (value) {
      console.log('fetching user', value)
      this.corporationList = []
      this.fetching = true
      supplier_list({ name: value }).then(({ data }) => {
        const result = data.entries || []
        this.corporationList = !value ? [] : result
      })
    },
    handleDataChange (value) {
      console.log(value)
    },
    handleLifeChange (value) {
      this.is_show = !!value
    },
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    },
    disabledStartDate (startValue) {
      const endValue = this.form.getFieldValue('self_life_end')
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate (endValue) {
      const startValue = this.form.getFieldValue('self_life_begin')
      console.log(startValue.valueOf())
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleEndChange (value) {
      console.log(`selected ${value}`)
      this.is_end = !!value
    },
    handleStartOpenChange (open) {
      console.log(open)
      this.startOpen = open
    },
    handleEndOpenChange (open) {
      this.endOpen = open
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleExhibitionGoodsSearch (value) {
      goods_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_goods = !value ? [] : result
      })
    },
    handleExhibitionGoodsChange (value) {
      console.log(value)
      const objneo = this.dataSource_wms_goods.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_goods)
      var obj = Object.assign({}, objneo)
      console.log(obj)
      // obj.tag_price = (obj.tag_price / 100).toFixed(2)
      // obj.sales_price = (obj.sales_price / 100).toFixed(2)
      // this.form.setFieldsValue({
      //   tag_price: obj.tag_price,
      //   sales_price: obj.sales_price
      // })
      // this.getbatchCommodity(obj.bonded_goods_id)
    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const customData = {
          item_no: data.code,
          gcode: data.hs_code,
          unit: data.unit,
          legal_unit: this.dictionaries.unit[data.legal_unit] + '',
          legal_unit2: this.dictionaries.unit[data.legal_unit2] + ''
        }
        console.log('====', data)
        const formData = pick(customData, ['item_no', 'gcode', 'legal_unit', 'legal_unit2', 'unit'])
        console.log('formData', formData)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    },
    find_key (obj, value, compare = (a, b) => a === b) {
      console.log('find_key================', value)
      if (value === '' || value === undefined) {
        return undefined
      }
      return Object.keys(obj).find(k => compare(obj[k], value)) + ''
    }
  }
}
</script>
